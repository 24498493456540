<template>
  <Page full>
    <Grid
      type="form"
      title="Forms"
      :multiple="true"
      :permanentFilters="{
        company_id: $store.state.session.company.company_id
      }"
      :showDefaultFilterPresets="false"
      :isMain="true"
      :onCreate="onCreate"
    >
    </Grid>
  </Page>
</template>

<script>
import Grid from '@/components/ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    Grid
  },
  methods: {
    onCreate(type, filters) {
      return this.$store.dispatch('Form/goToCreatePage', {
        type,
        filters
      })
    }
  }
}
</script>
